<template>
<div class='main'>
  <header-base :sear_Value="this.$route.query.data"></header-base>
  <div class="show_menu">
    <div class="menu_title">
      <div id="menutitle">
        <div class="title_div">
          <span class="navspan f1">已选</span>
          <div class="spanlist">
            <el-tag v-for="(tag, item) in tags" :key="item"
              closable
              size="small"
              @close="handleClose(tag, tags)">
              {{ tag }}
            </el-tag>
          </div>
          <button
            @click="handleClose(this.tags)">重置</button>
        </div>
        <!-- <div class="title_div">
          <span class="navspan">交货周期</span>
          <ul class="huotime">
            <li v-for="(i,index) in huotimes" :key="index"
            @click="handleAddOne(i, huotimes),filtrateInquire('time', index)">
              {{i}}
            </li>
          </ul>
        </div> -->
        <div class="title_div">
          <span class="navspan">品种</span>
          <ul class="huotime">
            <li v-for="i in pinzhongs" :key="i"
            @click="handleAddMulti(i.name),filtrateInquire('variety', i, pinzhongs)">
              {{i.name}}
            </li>
          </ul>
        </div>
        <!-- <div class="title_div">
          <span class="navspan">产地</span>
          <ul class="huotime">
            <li v-for="i in chandis" :key="i"
            @click="handleAddMulti(i),filtrateInquire('place', i, chandis)">
              {{i}}
            </li>
          </ul>
        </div> -->
        <div class="title_div">
          <ul class="selectul">
            <li class="selectli">
                <el-select v-model="salesvalue"
                placeholder="销量"
                @change="filtrateInquire('sale',salesvalue)"
                size="small">
                <el-option
                  v-for="item in optionsxiaoliang"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
            <li class="selectli">
                <el-select v-model="pricevalue"
                placeholder="价格"
                @change="filtrateInquire('price',pricevalue)"
                size="small">
                <el-option
                  v-for="item in optionsPrice"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="menu_body">
      <div id="menubody">
        <div class="showbody">
          <ul class="showul">
            <span v-if="totalnum!=0?false:true"
              class="showulspan">
              <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%9A%82%E6%97%A0%E6%90%9C%E7%B4%A2%E7%BB%93%E6%9E%9C.png" alt="">
              <p>没有找到数据!!</p></span>
            <li class="showli"
            v-for="(item,index) in yushouList" :key="index">
              <div class="yscont"
              @click="toDetails(item)">
                <span class="yushouCont">{{
                  item.previewStatus==0?'现货':
                  item.previewStatus==1?'预售':
                  item.previewStatus==3?'易货':''}}</span>
                <img :src="item.pic" alt="">
                <p class="p1 ptitle">
                  {{item.name}}
                </p>
                <p class="p1">
                  <span>
                      <i class="zititi c1">￥</i>{{item.price}}
                      <i class="zititi">/{{item.unit}}</i>
                  </span>
                </p>
                <!-- <p class="zititi">转售数：<i class="p2">{{item.number}}</i></p> -->
                <p class="zititi">截至日期：<i class="p2">{{item.offerExpiryDate}}</i></p>
              </div>
            </li>
          </ul>
        </div>
        <div class="showfooter" v-if="totalnum<pagesize?false:true">
          <el-pagination
            v-model:currentPage="currentPage"
            :page-size="pagesize"
            layout="prev, pager, next, jumper"
            :total="totalnum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue-demi'
import http from '../../api/http'
import HeaderBase from '../HeaderBase.vue'
import emitter from '../../tools/bus'
let tags = reactive([])
const huotimes = reactive(['次月', '10天内', '30天内', '半年内'])
let pinzhongs = reactive([])
const chandis = reactive(['中国', '巴西', '伊拉克', '叙利亚', '南非'])
const optionsPrice = ref([{ value: '降序', label: '价格降序' }, { value: '升序', label: '价格升序' }])
const optionsxiaoliang = ref([{ value: '降序', label: '销量降序' }, { value: '升序', label: '销量升序' }])

// 搜索接口
const search = (data) => {
  return http.get('/mall-portal/product/search', data)
}
// 品种(专题)数据
const topicList = (data) => {
  return http.get('/mall-portal/home/topicList')
}
export default {
  name: 'SearchMain',
  data () {
    return {
      seardata: '', // 0产品  or  1供应商
      salesvalue: ref(''),
      pricevalue: ref(''),
      paramsList: {
        deliveryPeriod: null, // 交货周期:0->次月；1->10天内；2->30天内；3->半年内；
        brandId: [], // 品种
        keyword: [], // 关键字
        originPlace: [], // 产地
        sort: null, // 排序字段:0->按相关度；1->销量从低到高；2->销量从高到低；3->价格从低到高；4->价格从高到低
        pageSize: 16,
        pageNum: 1
      },
      brandIdstr: '', // 根据品种查
      keywordStr: '', // 查关键字用的
      originPlaceStr: '', // 查产地

      // 存放总数据个数
      totalnum: ref(0),
      // 存放一页显示个数
      pagesize: ref(16),
      // 默认展示页
      currentPage: ref(1),
      yushouList: reactive([])// 展示数据
    }
  },
  components: { HeaderBase },
  methods: {
    async initData (data) { // 搜索接口
      console.log(data)

      const loading = this.$loading({
        lock: true,
        text: '加载中...'
      })
      const res = await search(data)
      console.log(res)
      if (res.data.code === 200) {
        this.totalnum = res.data.data.total
        this.yushouList = res.data.data.list
        loading.close()
      }
    },
    handleClose (name, tag, tagslist) { // 删除所选项 ,或者重置已选择
      // console.log(typeof name)
      // console.log(name, tag, tagslist)
      if (typeof name !== 'string') {
        this.tags.splice(0, name.length)
        this.keywordStr = null
      } else {
        this.tags.splice(tag.indexOf(name), 1)

        this.paramsList.deliveryPeriod = null

        pinzhongs.forEach(item => {
          // console.log(item)
          if (name === item.name) {
            this.paramsList.keyword.splice(this.paramsList.keyword.indexOf(item.id), 1)
            this.keywordStr = this.arrayToString(this.paramsList.keyword)
            return true
          }
        })

        // if (this.paramsList.keyword.indexOf(name) !== -1) {
        //   this.paramsList.keyword.splice(this.paramsList.keyword.indexOf(name), 1)
        //   this.keywordStr = this.arrayToString(this.paramsList.keyword)
        //   // console.log(this.keywordStr)
        // } else {
        //   this.paramsList.originPlace.splice(this.paramsList.originPlace.indexOf(name), 1)
        //   this.originPlaceStr = this.arrayToString(this.paramsList.originPlace)
        //   // console.log(this.originPlaceStr)
        // }
      }
      this.paramsList.pageNum = 1
      this.initData(
        {
          deliveryPeriod: this.paramsList.deliveryPeriod,
          sort: this.paramsList.sort,
          specialSubjectId: this.keywordStr,
          originPlace: this.originPlaceStr,
          // previewStatus: 1,
          pageSize: 16,
          pageNum: 1
        }
      )
    },
    handleAddOne (tag, tagslist) { // 单选添加
      // console.log(tag)
      for (let i = 0; i < tagslist.length; i++) {
        // console.log(tagslist[i])
        for (let j = 0; j < this.tags.length; j++) {
          if (tagslist[i] === this.tags[j]) {
            this.tags[j] = tag
            return
          }
        }
      }
      this.tags.push(tag)
    },
    handleAddMulti (tag) { // 多选 添加
      for (let j = 0; j < this.tags.length; j++) {
        if (tag === this.tags[j]) {
          return
        }
      }
      this.tags.push(tag)
    },
    /* name:判断是什么类型的查询
     * item:当前选择的查询字段
     * tagslist:当前所有类型
     */
    filtrateInquire (name, item, tagslist) { // 筛选 查询  方法
      // const all = this.tags
      // console.log(name, item)
      switch (name) {
        case 'time':
          this.paramsList.deliveryPeriod = item
          break
        case 'variety':
          this.paramsList.brandId = [] //
          this.paramsList.keyword.push(item.id)
          this.keywordStr = this.arrayToString(this.paramsList.keyword)
          // 关键字
          // this.paramsList.keyword.push(item)
          // this.keywordStr = this.arrayToString(this.paramsList.keyword)
          // console.log(this.keywordStr)
          break
        case 'place':
          this.paramsList.originPlace.push(item)
          this.originPlaceStr = this.arrayToString(this.paramsList.originPlace)
          // console.log(this.originPlaceStr)
          break
        case 'sale':
          if (item === '升序') {
            this.paramsList.sort = 1
          } else {
            this.paramsList.sort = 2
          }
          break
        case 'price':
          if (item === '升序') {
            this.paramsList.sort = 3
          } else {
            this.paramsList.sort = 4
          }
          break
        default:
          break
      }
      // console.log(toRaw(this.paramsList))
      this.paramsList = toRaw(this.paramsList)
      console.log(this.paramsList)
      this.initData({
        // specialSubjectId: this.brandIdstr, // 品种id
        deliveryPeriod: this.paramsList.deliveryPeriod,
        sort: this.paramsList.sort,
        specialSubjectId: this.keywordStr,
        originPlace: this.originPlaceStr,
        // previewStatus: 1,
        pageSize: 16,
        pageNum: this.paramsList.pageNum || 1
      })
    },
    arrayToString (selectTagslist) {
      // console.log(paramsList)
      const temp = []
      for (let j = 0; j < selectTagslist.length; j++) {
        if (temp.indexOf(selectTagslist[j]) === -1) {
          temp.push(selectTagslist[j])
        }
      }
      // console.log(temp)
      return temp.toString()
    },
    toDetails (data) {
      console.log(data)
      // this.$emit('dataToDetail', data)
      if (data.previewStatus === 0) { // 现货
        this.$router.push({
          path: '/cashcommodity/varietiy_details',
          query: {
            productSn: data.productSn
          }
        })
      } else if (data.previewStatus === 1) { // 预售
        this.$router.push({
          path: '/reservation/varietiy_details',
          query: {
            productSn: data.productSn
          }
        })
      } else if (data.previewStatus === 3) { // 易货
        this.$router.push({
          path: '/barter/detail',
          query: {
            id: data.id
          }
        })
      }
    },
    handleCurrentChange (val) { // 下一页
      // console.log(`current page: ${val}`)
      this.paramsList.pageNum = val
      console.log(this.paramsList.pageNum)
      if (this.seardata === '1') {
        const merchantName = this.$route.query.data
        this.initData({
          merchantName: merchantName,
          deliveryPeriod: this.paramsList.deliveryPeriod,
          sort: this.paramsList.sort,
          specialSubjectId: this.keywordStr,
          originPlace: this.originPlaceStr,
          // previewStatus: 1,
          pageSize: 16,
          pageNum: this.paramsList.pageNum
        })
        return
      }
      this.initData({
        deliveryPeriod: this.paramsList.deliveryPeriod,
        sort: this.paramsList.sort,
        specialSubjectId: this.keywordStr,
        originPlace: this.originPlaceStr,
        // previewStatus: 1,
        pageSize: 16,
        pageNum: this.paramsList.pageNum
      })
    }
  },
  mounted () {
    this.seardata = this.$route.query.sedata
    console.log(typeof this.seardata)
    if (this.seardata === '1') { // 供应商
      const merchantName = this.$route.query.data
      this.initData({ pageSize: 16, pageNum: 1, merchantName: merchantName })
    } else { // 商品
      const keyword = this.$route.query.data
      this.initData({ pageSize: 16, pageNum: 1, keyword: keyword })
    }
    // 公共搜索框传值
    emitter.on('searchHeaderBase', (data) => {
      console.log(data)
      this.seardata = data.sedata
      console.log(this.seardata)
      this.initData({ pageSize: 16, pageNum: 1, keyword: data.data })
    })
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    // 查品种
    pinzhongs = reactive([])
    tags = reactive([])
    const getVarietyList = async () => {
      const res = await topicList()
      // console.log(res)
      if (res.data.code === 200) {
        res.data.data.forEach(item => {
          pinzhongs.push({
            name: item.name,
            id: item.id
          })
        })
        console.log(pinzhongs)
        // console.log(...pinzhongs)
      }
    }
    onMounted(() => {
      getVarietyList()
    })
    return {
      tags,
      huotimes,
      pinzhongs,
      chandis,
      optionsPrice,
      optionsxiaoliang,
      handleSizeChange,
      getVarietyList
    }
  }
}
</script>
<style lang='scss'>
@import '../../style/viriables.scss';
.main{
  background: #F2EFED;
  width: 100%;
  min-height: 100vh;
  .show_menu{
    width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
    .menu_title{
      margin-top: 10px;
      #menutitle{
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        background: #ffffff;
        .title_div{
          display: flex;
          height: 49px;
          justify-content: right;
          align-items: center;
          border-bottom: 1px solid #F2EFED;
          .selectul{
            display: flex;
            width: 220px;
            justify-content: space-between;
            .selectli{
              width: 100px;
            }
          }
          .navspan{
            width: 100px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color:#333333;
          }
          .f1{
            color: #C6824E;
          }
          .huotime{
            flex: 1;
            display: flex;
            li{
              margin-right: 20px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
            }
          }
          .spanlist{
            flex: 1;
            .el-tag{
              margin-right: 10px;
              border-color: #C6824E;
              color: #C6824E;
              .el-icon{
                color: #C6824E;
              }
              .el-icon:hover{
                color: #ffffff;
                --el-tag-hover-color: #8b553b;
              }
            }
          }
          button{
            width: 60px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            border-radius: 4px;
          }
        }
        .title_div:last-child{
          border: 0;
          // height: 32px;
        }
      }
    }
    .menu_body{
      width: 100%;
      display: flex;
      #menubody{
        margin-top: 10px;
        width: 100%;
        flex: 1;
        display: flex;
        flex-flow: column;
        .showbody{
          width: 100%;
          flex: 1;
          .showul{
            min-height: 100px;
            // background: $divbgColor;
            .showulspan{
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              width: 100%;
              color: #AC703B;
              line-height: 100px;
              font-size: 30px;
              text-align: center;
              p{
                // flex: 1;
                width: 100%;
              }
            }
            .showli{
              float: left;
              width: 300px;
              box-sizing: border-box;
              padding: 20px;
              background: $divbgColor;
              border-right: 1px solid $bgColor;
              border-bottom: 1px solid $bgColor;
              .yscont{
                position: relative;
                .yushouCont{
                  width: 30px;
                  height: 20px;
                  font-size: 12px;
                  color: #ffffff;
                  text-align: center;
                  line-height: 20px;
                  background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
                  border-radius: 0px 0px 4px 0px;
                  position: absolute;
                }
                p{
                  line-height: 35px;
                }
                img{
                  width: 260px;
                  height: 260px;
                }
                .p1{
                  color: #333333;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  width: 260px;
                  span{
                    display: inline-block;
                    // float: right;
                    text-align: right;
                    color: #c88756;
                    font-size: 20px;
                    .c1{
                      color: #c88756;
                    }
                  }
                }
                .ptitle{
                  line-height: 21px;
                  margin: 10px 0;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
                .zititi{
                  color: #999999;
                  font-size: 14px;
                  .p2{
                    color: #333333;
                  }
                }
              }
            }
          }
        }
        .showfooter{
          height: 100px;
          // background: red;
          margin: 0 auto;
          display: flex;
          align-items: center;
          .el-pagination{
            // line-height: 100px;
            .el-pager{
              margin: 0 20px;
              .number,.btn-quicknext{
                margin: 0 5px;
                width: 40px;
                height: 40px;
                border: 1px solid transparent;
                line-height: 40px;
              }
              .active{
                background: #ffffff;
                color: #C6824E;
                border-color: #C6824E;
              }
              .number:hover{
                color: #C6824E;
              }
              .el-icon{
                height: 40px;
                line-height: 40px;
              }
            }
            .btn-prev,.btn-next,.el-pagination__jump{
              height: 40px;
              line-height: 40px;
              .el-input{
                height: 40px;
                .el-input__inner{
                  height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
